import React from 'react'
import Home from './Home'
import Service from './Service'

const HealthCare = () => {
  return (
    <div>
      <Home/>
      <Service/>
    </div>
  )
}

export default HealthCare
