import React from 'react'
import AboutHome from './AboutHome'
import Home from './Home'

const About = () => {
  return (
    <div>
      <Home/>
      <AboutHome/>
    </div>
  )
}

export default About
