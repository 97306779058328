import React from 'react'
import Home from './Home'

const Carrer = () => {
  return (
    <div>
      <Home/>
    </div>
  )
}

export default Carrer
