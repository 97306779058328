import React from 'react'
import Home from './Home'
import Service from './Service'

const Gaming = () => {
  return (
    <div>
      <Home/>
      <Service/>
    </div>
  )
}

export default Gaming
