import React from 'react'
import Home from './Home'
import Service from './Service'

const Food = () => {
  return (
    <div>
        <Home/>
        <Service/>
    </div>
  )
}

export default Food
